import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule, REMOVE_STYLES_ON_COMPONENT_DESTROY } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxSpinnerModule } from "ngx-spinner";
import { MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";

import { AppRoutingModule } from "../app/app-routing.module";
import { AppComponent } from "../app/app.component";
import { ErrorInterceptor } from "../app/core/interceptor/error.interceptor";
import { JwtInterceptor } from "../app/core/interceptor/jwt.interceptor";
import { HomeModule } from "../app/features/home/home.module";
import { LoginModule } from "../app/features/login/login.module";
import { SsoModule } from "../app/features/sso/sso.module";
import { UserSettingsModule } from "../app/features/user-settings/user-settings.module";
import { SharedModule } from "../app/shared/shared.module";
import { LoadingInterceptor } from "./core/interceptor/loading.interceptor";
import { ConfigService } from "./core/services/config.service";

export const configFactory = (configService: ConfigService) => {
  return (): Promise<void> => configService.loadConfig();
};

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    SharedModule,
    LoginModule,
    SsoModule,
    HomeModule,
    UserSettingsModule,
    AppRoutingModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: configFactory, deps: [ConfigService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: REMOVE_STYLES_ON_COMPONENT_DESTROY, useValue: true },
    MessageService,
    DialogService,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}

import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { NotFoundComponent } from './shared/errors/not-found/not-found.component';
import { PublicLayoutComponent } from './shared/public-layout/public-layout.component';

const PUBLIC_ROUTES: Routes = [
  { path: 'not-found', component: NotFoundComponent, title: 'Not Found' },
  { path: '**', component: NotFoundComponent, pathMatch: 'full', title: 'Not Found' }
];

const routes: Routes = [
  { path: '', component: PublicLayoutComponent, children: PUBLIC_ROUTES }
];

const routerOptions: ExtraOptions = {
  enableViewTransitions: false
};

@NgModule({
  imports: [
    RouterModule.forRoot(routes, routerOptions)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

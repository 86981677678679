import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AccordionModule } from "primeng/accordion";
import { AvatarModule } from "primeng/avatar";
import { AvatarGroupModule } from "primeng/avatargroup";
import { BadgeModule } from "primeng/badge";
import { BlockUIModule } from "primeng/blockui";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";
import { CarouselModule } from "primeng/carousel";
import { CheckboxModule } from "primeng/checkbox";
import { ChipModule } from "primeng/chip";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ContextMenuModule } from "primeng/contextmenu";
import { DialogModule } from "primeng/dialog";
import { DividerModule } from "primeng/divider";
import { DropdownModule } from "primeng/dropdown";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { InputMaskModule } from "primeng/inputmask";
import { InputNumberModule } from "primeng/inputnumber";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { ListboxModule } from "primeng/listbox";
import { MegaMenuModule } from "primeng/megamenu";
import { MenuModule } from "primeng/menu";
import { MenubarModule } from "primeng/menubar";
import { MessageModule } from "primeng/message";
import { MessagesModule } from "primeng/messages";
import { MultiSelectModule } from "primeng/multiselect";
import { PaginatorModule } from "primeng/paginator";
import { PanelModule } from "primeng/panel";
import { PasswordModule } from "primeng/password";
import { ProgressBarModule } from "primeng/progressbar";
import { RadioButtonModule } from "primeng/radiobutton";
import { RippleModule } from "primeng/ripple";
import { SelectButtonModule } from "primeng/selectbutton";
import { SliderModule } from "primeng/slider";
import { StepsModule } from "primeng/steps";
import { TableModule } from "primeng/table";
import { TabViewModule } from "primeng/tabview";
import { ToastModule } from "primeng/toast";
import { TooltipModule } from "primeng/tooltip";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MessagesModule,
    MessageModule,
    ReactiveFormsModule,
    BlockUIModule,
    ChipModule,
    PaginatorModule,
    SliderModule,
    BadgeModule,
    MultiSelectModule,
    BreadcrumbModule,
    ContextMenuModule,
    DynamicDialogModule,
    ToastModule,
    StepsModule,
    FormsModule,
    MenubarModule,
    MegaMenuModule,
    AccordionModule,
    CalendarModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    RippleModule,
    PanelModule,
    CardModule,
    CarouselModule,
    MenuModule,
    AvatarModule,
    AvatarGroupModule,
    TooltipModule,
    TableModule,
    TabViewModule,
    DropdownModule,
    RadioButtonModule,
    CheckboxModule,
    ListboxModule,
    SelectButtonModule,
    InputNumberModule,
    DividerModule,
    ProgressBarModule,
    DialogModule,
    InputMaskModule,
    InputSwitchModule,
    ConfirmDialogModule
  ],
  exports: [
    MessagesModule,
    MessageModule,
    ReactiveFormsModule,
    BlockUIModule,
    ChipModule,
    PaginatorModule,
    SliderModule,
    BadgeModule,
    MultiSelectModule,
    BreadcrumbModule,
    ContextMenuModule,
    DynamicDialogModule,
    ToastModule,
    StepsModule,
    FormsModule,
    MenubarModule,
    MegaMenuModule,
    AccordionModule,
    CalendarModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    RippleModule,
    PanelModule,
    CardModule,
    CarouselModule,
    MenuModule,
    AvatarModule,
    AvatarGroupModule,
    TooltipModule,
    TableModule,
    TabViewModule,
    DropdownModule,
    RadioButtonModule,
    CheckboxModule,
    ListboxModule,
    SelectButtonModule,
    InputNumberModule,
    DividerModule,
    ProgressBarModule,
    DialogModule,
    InputMaskModule,
    InputSwitchModule,
    ConfirmDialogModule
  ]
})
export class ExternalModule {}

import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../../core/services/account.service';
import { Member } from "../../../core/models/login/member";

/*
 * WARNING: This component was not fully implemented
 */

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  private member: Member;
  newPassword: string;
  confirmPassword: string;
  validationErrors: string[] = [];
  fullName: string;
  officeEmail: string;
  homeEmail: string;


  constructor(private accountService: AccountService) {
    this.member = this.accountService.getCurrentMember();
    this.fullName = this.member.fullName;

    this.accountService.checkIfPwdExpired().toPromise().then(
      response => {

        if (response) {
          [].forEach.call(document.querySelectorAll(`.navigation-container`), function (el) {
            el.style.display = 'none';
          });

          [].forEach.call(document.querySelectorAll('.left-nav'), function (el) {
            el.style.display = 'none';
          });

          [].forEach.call(document.querySelectorAll('app-two-factor'), function (el) {
            el.style.display = 'none';
          });

        }

      },
      (error) => {
        console.log('Error while checking if password expired');
        console.log(error);
      });
  }

  ngOnInit(): void {

  }

}

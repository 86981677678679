import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublicLayoutComponent } from '../../shared/public-layout/public-layout.component';
import { SsoComponent } from '../sso/sso.component';
import { LoginComponent } from './login/login.component';
import { SiteDetailsResolver } from './site-details-resolver.service';

const PUBLIC_ROUTES: Routes = [
  { path: 'login', component: LoginComponent, resolve: { siteDetails: SiteDetailsResolver}, title: 'Login'},
  { path: 'sso', component: SsoComponent, pathMatch: 'prefix', title: 'SSO Login' },
  { path: '', redirectTo: 'login', pathMatch: 'full', title: 'Login' },
];

const routes: Routes = [
  { path: '', component: PublicLayoutComponent, children: PUBLIC_ROUTES }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { SecureLayoutComponent } from '../shared/secure-layout/secure-layout.component';
import { ProfileComponent } from '../shared/left-nav/profile/profile.component';
import { ProfileTFAResolver } from './left-nav/profile/profileTFA-resolver.service';

const SECURE_ROUTES: Routes = [
  { path: 'profile', component: ProfileComponent, resolve: { profileTFA: ProfileTFAResolver}, title: 'Profile'}
];

const routes: Routes = [{
  path: '',
  component: SecureLayoutComponent,
  runGuardsAndResolvers: 'always',
  canActivate: [AuthGuard],
  children: SECURE_ROUTES
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})


export class SharedRoutingModule { }

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";

import { Config } from "../models/config";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  config: Config;

  constructor(private http: HttpClient) {}

  async loadConfig(): Promise<void> {
    this.config = await firstValueFrom(this.http.get<Config>("./assets/config.json"));
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subscription, timer } from 'rxjs';
import { IdleUserService } from '../../../core/services/idle-user.service';
import { environment } from '../../../../environments/environment';

/*
 * WARNING: This component was not fully implemented
 */

@Component({
  selector: 'app-idle-user-modal',
  templateUrl: './idle-user-modal.component.html',
  styleUrls: ['./idle-user-modal.component.scss']
})
export class IdleUserModalComponent implements OnInit, OnDestroy {
  countdown: Subscription;
  counter = environment.idleModalTimeout;
  tick = 1000;
  hrAdminRoute: string;
  enableHrAdmin: boolean = false;

  constructor(
    private idleUser: IdleUserService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) { }

  ngOnDestroy(): void {
    this.countdown = null;
  }

  ngOnInit(): void {
    this.countdown = timer(0, this.tick).subscribe(() => this.checkCounter(this.counter));
  }

  checkCounter(counter: number) {
    this.counter--;
    if (counter < 2) this.logout();
  }

  logout() {
    this.hrAdminRoute = "Account/Logoff";
    this.enableHrAdmin = true;
  }

   resetTimer() {
    this.idleUser.resetTimer();
    this.ref.close();
    this.ref = null;
  }

}
